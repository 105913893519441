import { Button, Table, Modal, StatusIndicator, Box } from '@amzn/awsui-components-react';
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import shortUUID from 'short-uuid';

import EndLabsModal from '@/components/endLabsModal/EndLabsModal';
import IngressTableHeader from '@/components/ingressTableHeader/IngressTableHeader';
import { messages as studentRosterTableMessages } from '@/components/studentRosterTable/StudentRosterTable.messages';
import { useClassroomData } from '@/data/useClassroomData';
import { endLabs } from '@/modules/api';
import { removeCourseNameFromLabTitle, useFlags } from '@/utils';
import { useAppNotifications } from '@/utils/appNotifications';
import { useDecodedParams } from '@/utils/paramHooks';
import { usePooledLabs } from '@/utils/pooledLabs';
import { nowInUnixTimeInSec } from '@/utils/timestamp-utils';

import messages from './IngressTable.messages';
import {
    computeTableItems,
    computeTableForPooledLabs,
    getLabStatus,
    isPooledLab,
    LabStatus,
} from './IngressTable.utils';

import './IngressTable.scss';

const {
    tableHeaderLabStatus,
    tableHeaderStudent,
    tablePreloadingLabStatus,
    tablePreloadedLabStatus,
    tableEndedLabStatus,
    tableFailedLabStatus,
    studentLabEnded,
    cannotIngress,
    close,
    tableEmpty,
    closeModalLabelText,
    tableExceedLabLimit,
    tableRunningLabStatus,
    tableExpiredLabStatus,
} = messages;

const IngressTable = ({
    studentTrainings = [],
    loading,
    trainingLoading,
    trainingRefetch,
    labId,
    setRegion,
    selectedRegion,
    hasStudentRoster,
    globals = window,
}) => {
    const { formatMessage } = useIntl();
    const flags = useFlags();
    const { addNotification } = useAppNotifications();
    const { classroomId, labNumber } = useDecodedParams();
    const {
        classData: { classroom = {}, content = [], course },
    } = useClassroomData(classroomId);
    const { request: pooledLabRequest } = usePooledLabs();
    const [isError, setError] = useState(false);
    const [selectedItems, selectedItemsSet] = useState([]);
    const [showEndLabsModal, showEndLabsModalSet] = useState(false);

    useEffect(() => {
        if (classroom.startsOn > nowInUnixTimeInSec()) {
            addNotification({
                type: 'error',
                content: formatMessage(messages.preloadClassNotStarted),
                dismissible: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [labId, classroom.startsOn]);

    useEffect(() => {
        selectedItemsSet([]);
    }, [labId, selectedItemsSet]);

    const onPreloadClick = () => {
        pooledLabRequest({ userKeys: selectedItems.map((item) => item.userKey) });
    };

    const performEndLabsCall = async () => {
        await endLabs({
            blueprintArn: labId,
            classroomId,
            clientToken: shortUUID.generate(),
            userKeys: (selectedItems || []).map((s) => s.userKey),
        });
        showEndLabsModalSet(false);
        setTimeout(trainingRefetch, 2000);
    };

    const ariaStatusWrapper = (statusType, statusText, student) => {
        const formattedStatusText = formatMessage(statusText);
        return (
            <StatusIndicator type={statusType}>
                <span
                    role='status'
                    aria-label={formatMessage(messages.tableStatusAriaLabel, {
                        status: formattedStatusText,
                        student,
                    })}
                >
                    <span aria-hidden='true'>{formattedStatusText}</span>
                </span>
            </StatusIndicator>
        );
    };

    const formatLabStatus = (status, email) => {
        switch (status) {
            case LabStatus.Preloading:
                return ariaStatusWrapper('info', tablePreloadingLabStatus, email);
            case LabStatus.Ready:
            case LabStatus.Preloaded:
                return ariaStatusWrapper('success', tablePreloadedLabStatus, email);
            case LabStatus.Ended:
                return ariaStatusWrapper('stopped', tableEndedLabStatus, email);
            case LabStatus.Failed:
                return ariaStatusWrapper('error', tableFailedLabStatus, email);
            case LabStatus.ExceedLabLimit:
                return ariaStatusWrapper('warning', tableExceedLabLimit, email);
            case LabStatus.Running:
                return ariaStatusWrapper('in-progress', tableRunningLabStatus, email);
            case LabStatus.Expired:
                return ariaStatusWrapper('stopped', tableExpiredLabStatus, email);
            case LabStatus.NoLab:
            // fall through
            default:
                return '-';
        }
    };

    const columnDefinitions = [
        !hasStudentRoster && {
            id: 'student',
            header: formatMessage(tableHeaderStudent),
            cell: ({ studentNumber }) => studentNumber || '-',
        },
        hasStudentRoster && {
            id: 'firstName',
            header: formatMessage(studentRosterTableMessages.headerFirstName),
            cell: ({ firstName }) => firstName || '-',
        },
        hasStudentRoster && {
            id: 'lastName',
            header: formatMessage(studentRosterTableMessages.headerLastName),
            cell: ({ lastName }) => lastName || '-',
        },
        hasStudentRoster &&
            flags.pooledLabs && {
                id: 'email',
                header: formatMessage(studentRosterTableMessages.headerEmail),
                cell: ({ email }) => email || '-',
            },
        {
            id: 'labRegion',
            header: formatMessage(studentRosterTableMessages.headerLabRegion),
            cell: ({ labRegion, metaData }) =>
                labRegion || (metaData?.labRegions?.length && metaData?.labRegions[0]) || '-',
        },
        {
            id: 'labStatus',
            header: formatMessage(tableHeaderLabStatus),
            cell: (lab) => formatLabStatus(getLabStatus(lab), lab.email),
        },
    ].filter((columnDefinition) => columnDefinition);

    const unsortedItems = flags.pooledLabs
        ? computeTableForPooledLabs(studentTrainings, labId, classroom)
        : computeTableItems(
              (studentTrainings || []).filter((t) => !isPooledLab(t)),
              labId,
          );

    const tableItems = sortBy(unsortedItems, ['sortingRank', 'studentNumber']);

    const onSelectionChange = ({ detail }) => {
        selectedItemsSet(detail.selectedItems);
    };
    const sanitizedLabTitle = removeCourseNameFromLabTitle(
        content[parseInt(labNumber) - 1]?.title,
        course?.title,
    );
    return (
        <div data-testid='ingress-table'>
            <Modal
                visible={isError}
                onDismiss={() => setError(false)}
                header={formatMessage(cannotIngress)}
                closeAriaLabel={formatMessage(closeModalLabelText)}
                footer={
                    <Box float='right'>
                        <Button onClick={() => setError(false)} variant='primary'>
                            {formatMessage(close)}
                        </Button>
                    </Box>
                }
            >
                {formatMessage(studentLabEnded)}
            </Modal>
            <EndLabsModal
                visible={showEndLabsModal}
                onCancel={() => showEndLabsModalSet(false)}
                labTitle={sanitizedLabTitle}
                endDate={classroom.endsOn}
                onConfirm={performEndLabsCall}
                numberOfStudents={selectedItems?.length}
                classCapacity={classroom?.classCapacity}
            />
            <Table
                renderAriaLive={({ firstIndex, lastIndex }) => {
                    return formatMessage(messages.itemsDisplayedLabel, {
                        firstIndex,
                        lastIndex,
                        totalItemsCount: tableItems?.length ?? 0,
                    });
                }}
                ariaLabels={{
                    selectionGroupLabel: formatMessage(messages.labsSelectionLabel),
                    allItemsSelectionLabel: (data) =>
                        data.selectedItems.length === 1
                            ? formatMessage(messages.allLabsSelectedLabelSingle, {
                                  amount: data.selectedItems.length,
                              })
                            : formatMessage(messages.allLabsSelectedLabelMulti, {
                                  amount: data.selectedItems.length,
                              }),
                    itemSelectionLabel: (_data, item) =>
                        formatMessage(messages.itemSelectionLabel, {
                            identifier: item.studentNumber,
                        }),
                }}
                selectionType='multi'
                selectedItems={selectedItems}
                onSelectionChange={onSelectionChange}
                loading={loading}
                resizableColumns
                trackBy={'userKey'}
                stickyHeader
                header={
                    <IngressTableHeader
                        {...{
                            onPreloadClick,
                            performEndLab: () => showEndLabsModalSet(true),
                            trainingLoading,
                            trainingRefetch,
                            errorSet: setError,
                            selectedItems,
                            globals,
                            tableItems,
                            setRegion,
                            selectedRegion,
                        }}
                    />
                }
                columnDefinitions={columnDefinitions}
                items={tableItems}
                isItemDisabled={(item) => item.userKey.startsWith('pooled')}
                empty={
                    <Box textAlign='center'>
                        <Box margin={{ bottom: 'xs' }} padding={{ top: 's' }}>
                            <b>{formatMessage(tableEmpty)}</b>
                        </Box>
                    </Box>
                }
            ></Table>
        </div>
    );
};

export default IngressTable;
