import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import messages from '@/components/ingressTable/IngressTable.messages';
import { useClassroomData } from '@/data/useClassroomData';
import { getTrainingConfiguration, putTrainingConfiguration } from '@/modules';
import { useFlags } from '@/utils';
import { useAppNotifications } from '@/utils/appNotifications';
import { useDecodedParams, useParseUrlParamsForLabData } from '@/utils/paramHooks';

const PENDING_DEFAULT_LAB_REGION = '...';

export const useLabRegionSelection = () => {
    const { formatMessage } = useIntl();
    const { addNotification } = useAppNotifications();
    const flags = useFlags();
    const { classroomId, labNumber } = useDecodedParams();
    const { classData } = useClassroomData(classroomId);
    const classroomArn = classData.classroom?.classroomArn;
    const { supportedRegions = [], contentId: labArn } = useParseUrlParamsForLabData();

    const defaultLabRegion = supportedRegions[0] ?? PENDING_DEFAULT_LAB_REGION;

    const [displayedRegion, displayedRegionSet] = useState<string>(defaultLabRegion);

    const setRegion = useCallback(
        async (region: string) => {
            displayedRegionSet(region);

            if (!flags.persistedRegionSelection) return;
            try {
                await putTrainingConfiguration({
                    context: classroomArn,
                    contentArn: labArn,
                    contentType: 'SPL',
                    configuration: {
                        targetRegions: [region],
                    },
                });
            } catch (error) {
                addNotification({
                    type: 'warning',
                    content: formatMessage(messages.putRegionFailure, {
                        labNumber,
                    }),
                });
            }
        },
        [
            classroomArn,
            labArn,
            labNumber,
            flags.persistedRegionSelection,
            displayedRegionSet,
            addNotification,
            formatMessage,
        ],
    );

    const establishSelectedRegion = useCallback(
        async (fallbackRegion: string) => {
            if (!flags.persistedRegionSelection) {
                await setRegion(fallbackRegion);
                return;
            }

            try {
                const trainingConfiguration = await getTrainingConfiguration({
                    context: classroomArn,
                    contentArn: labArn,
                });

                const storedRegion = trainingConfiguration.configuration.targetRegions[0];
                if (storedRegion) {
                    displayedRegionSet(storedRegion);
                } else {
                    await setRegion(fallbackRegion);
                }
            } catch (err) {
                // We are not able to confirm a stored region.
                // We will use the fallback, but we should only set one banner item in this scenario.
                addNotification({
                    type: 'info',
                    content: formatMessage(messages.getRegionFailure, {
                        labNumber,
                    }),
                });
                await setRegion(fallbackRegion);
            }
        },
        // setRegion and addNotification cause multiple rerenders
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            classroomArn,
            labArn,
            labNumber,
            flags.persistedRegionSelection,
            displayedRegionSet,
            formatMessage,
        ],
    );

    useEffect(() => {
        if (defaultLabRegion === PENDING_DEFAULT_LAB_REGION) return;

        establishSelectedRegion(defaultLabRegion);
    }, [defaultLabRegion, labArn, establishSelectedRegion]);

    return { setRegion, selectedRegion: displayedRegion };
};
