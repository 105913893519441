import { Box, Button, Container, ContentLayout, Header, Modal } from '@amzn/awsui-components-react';
import { get as _get } from 'lodash';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import { LabNavigation, IngressTable, ManageClassDropdown } from '@/components';
import { CLASS_ACCESS_TYPES } from '@/components/classForm/ClassForm.utils';
import { LabPoolDetails } from '@/components/pooledLabs/labPoolDetails';
import { CreateProvisionPoolModal } from '@/components/pooledLabs/provisionPoolModal';
import { Layout } from '@/containers/Layout';
import { useClassroomData } from '@/data/useClassroomData';
import { useLabRegionSelection } from '@/data/useLabRegionSelection';
import { getStudentTrainingsByClassroomId } from '@/graphql/queries';
import { executeRequest } from '@/modules/api';
import { removeCourseNameFromLabTitle } from '@/utils';
import { classDetailPageBreadcrumb, classesPageBreadcrumb } from '@/utils/breadcrumbs';
import { useDecodedParams, useParseUrlParamsForLabData } from '@/utils/paramHooks';
import { PooledLabsProvider } from '@/utils/pooledLabs';

import messages from './Lab.messages';

const errorMessages = {
    'getStudentTrainingsByClassroomId::403': messages.getStudentTrainingsByClassroomId403Error,
    default: messages.cannotGetStudentLabs,
};

const A_MINUTE = 60000;

const useQueryOptions = {
    refetchOnWindowFocus: false,
    refetchInterval: A_MINUTE,
};

const Lab = () => {
    const { classroomId, labNumber } = useDecodedParams();
    const { contentId: labId, title: labTitle } = useParseUrlParamsForLabData();
    const { formatMessage } = useIntl();
    const { setRegion, selectedRegion } = useLabRegionSelection();
    const { classData } = useClassroomData(classroomId);
    const { content, classroom } = classData;
    const [getTrainingsError, getTrainingsErrorSet] = useState(false);
    const hasStudentRoster = classroom?.accessType === CLASS_ACCESS_TYPES.roster;

    const queryParams = {
        classroomId,
        accessType: classroom?.accessType,
    };
    if (hasStudentRoster) {
        queryParams.accessType = CLASS_ACCESS_TYPES.roster;
    }

    const { data, isLoading, isFetching, refetch, error } = useQuery(
        [classroomId, queryParams],
        () =>
            executeRequest({
                operation: getStudentTrainingsByClassroomId,
                params: queryParams,
            }),
        {
            ...useQueryOptions,
            enabled: !!classData?.classroom,
        },
    );

    useEffect(() => {
        if (error) {
            const msg =
                errorMessages[`${error.path}::${error.statusCode}`] || errorMessages.default;
            getTrainingsErrorSet(msg);
        }
    }, [error]);

    /**
     * Currently we are getting all training from the activity service.
     * This has some complications.
     *
     * 1. A training record can reference old lab arns
     * 2. A training record may be in an error state
     * 3. We do not keep state, each student has more than one "active" lab, this is because
     * we are not onboarded to the labs hook notification. https://sim.amazon.com/issues/BKR-2236
     */

    const studentTrainingsByClass = data?.getStudentTrainingsByClassroomId.studentTrainings ?? [];
    const studentTrainingsByCurrentLabId = studentTrainingsByClass.filter(
        ({ arn }) => arn === labId,
    );

    const courseTitle = classData?.course?.title;
    return (
        <PooledLabsProvider
            classroomId={classroomId}
            langLocale={classroom?.langLocale}
            studentTrainings={studentTrainingsByCurrentLabId}
            labId={labId}
            batchRegion={selectedRegion}
        >
            <Layout
                breadcrumbItems={[
                    classesPageBreadcrumb(formatMessage),
                    classDetailPageBreadcrumb(formatMessage, encodeURIComponent(classroomId)),
                    { text: formatMessage(messages.sectionTitle) },
                ]}
                navigation={<LabNavigation content={content} courseTitle={courseTitle} />}
            >
                <Helmet>
                    <title>
                        {formatMessage(messages.pageTitle)} -{' '}
                        {`${formatMessage(messages.labTitle)} ${labNumber}`}
                    </title>
                </Helmet>
                <ContentLayout
                    disableOverlap
                    disablePadding
                    header={
                        <Header variant='h1' actions={<ManageClassDropdown />}>
                            {courseTitle}
                        </Header>
                    }
                >
                    <Container
                        header={
                            <Header
                                variant='h2'
                                actions={
                                    <CreateProvisionPoolModal selectedRegion={selectedRegion} />
                                }
                                description={formatMessage(messages.sectionSubheader)}
                            >
                                {removeCourseNameFromLabTitle(labTitle, courseTitle)}
                            </Header>
                        }
                    >
                        <LabPoolDetails
                            trainings={studentTrainingsByCurrentLabId}
                            selectedRegion={selectedRegion}
                        />
                        <IngressTable
                            labId={labId}
                            loading={isLoading}
                            langLocale={classroom?.langLocale}
                            studentTrainings={studentTrainingsByClass}
                            trainingRefetch={refetch}
                            trainingLoading={isFetching}
                            hasStudentRoster={hasStudentRoster}
                            setRegion={setRegion}
                            selectedRegion={selectedRegion}
                        />
                    </Container>
                </ContentLayout>

                {getTrainingsError ? (
                    <Modal
                        visible
                        onDismiss={() => getTrainingsErrorSet(false)}
                        header={formatMessage(messages.somethingWentWrongTrainings)}
                        closeAriaLabel={formatMessage(messages.closeModalLabelText)}
                        footer={
                            <Box float='right'>
                                <Button
                                    onClick={() => getTrainingsErrorSet(false)}
                                    variant='primary'
                                    data-testid='lab-training-errors-modal__close-btn'
                                >
                                    {formatMessage(messages.close)}
                                </Button>
                            </Box>
                        }
                    >
                        {formatMessage(getTrainingsError)}
                    </Modal>
                ) : null}
            </Layout>
        </PooledLabsProvider>
    );
};

export default Lab;
